import React from 'react';
import { Outlet } from 'react-router-dom';
import {Box,Container} from '@mui/material';
import Header from '../component/main/Header'
import Footer from '../component/main/Footer'


const MainLayout = () => {
  return (
    <Box>
      <Header/>
      <Container className="wablastersApp" style={{padding:0}} p={0} maxWidth={true} component="div">
          <Outlet/>
      </Container>
      <Footer/>
    </Box>
  );
};

export default MainLayout;
