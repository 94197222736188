import React from 'react';
import TermLayout from '../layout/TermLayout';
import About  from '../page/term/About'
import Faqs  from '../page/term/Faqs'
import ReturnRefund from '../page/term/ReturnRefund'
import PageLayout from '../layout/PageLayout'
import TermsCondition from '../page/term/TermsCondition'
import Support from '../page/term/Support'
import PrivacyPolicy from '../page/term/PrivacyPolicy'

const TermRoutes =  {
    path: '/',
    element: (
        <TermLayout/>
    ),
    children: [
        {
            path: '/about-us',
            element: <PageLayout><About/></PageLayout>
        },
        {
            path: '/terms',
            element: <PageLayout><TermsCondition/></PageLayout>
        },
        {
            path: '/support',
            element: <PageLayout><Support/></PageLayout>
        },
        {
            path: '/faqs',
            element: <PageLayout><Faqs/></PageLayout>
        },
        {
            path: '/privacy',
            element: <PageLayout><PrivacyPolicy/></PageLayout>
        },
        {
            path: '/return-refund',
            element: <PageLayout><ReturnRefund/></PageLayout>
        },
    ]
}

export default TermRoutes;

