import React,{useState} from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';

export default function CustomeDrawer({open,closeHandler}) {
    const themes = useTheme();
    const {appBar} = themes

    const toggleDrawer = (newOpen) => () => {
        closeHandler(newOpen);
    };


    const goToexe = () =>{
        window.open('https://chromewebstore.google.com/detail/wablasters-bulk-wa-messag/hmfggmookomommnebacphkkignbollcm', '_blank');
    }

    const DrawerList = (
        <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
            <List>
                {['Pricing', 'FAQs',].map((text, index) => (
                    <ListItem key={text} disablePadding>
                        <ListItemButton>
                            <Box>
                                <Typography sx={appBar.headerDrawerMenu}>{text}</Typography>
                            </Box>
                        </ListItemButton>
                    </ListItem>
                ))}
                <Divider/>
                <ListItem disablePadding>
                    <ListItemButton>
                        <Box>
                            <Button  onClick={()=>goToexe()} sx={appBar.btn}>Try for Free</Button>
                        </Box>
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton>
                        <Box>
                            <Button sx={appBar.btn}>Get Premium</Button>
                        </Box>
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    );

    return (
        <div>
            <Drawer open={open} onClose={toggleDrawer(false)}>
                {DrawerList}
            </Drawer>
        </div>
    );
}
