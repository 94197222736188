export async function priceWaitlistData(email) {
    let data = {email}
    let url = 'https://api.wablasters.com/api/v2/price-wait/'
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    return response.json();
}


export async function getLicence(sessionId) {
  let url = `https://api.wablasters.com/api/v3/session/${sessionId}/`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
  });
  return response.json();
}
  
export async function checkout(data) {
  let url = 'https://api.wablasters.com/api/v3/checkout/'
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
  return response.json();
}
