import React from 'react'
import { useTheme } from '@mui/material/styles';
import { Container,Box, Grid, Typography } from '@mui/material';
import LaptopWindowsIcon from '@mui/icons-material/LaptopWindows';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import GridViewIcon from '@mui/icons-material/GridView';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import BatchPredictionIcon from '@mui/icons-material/BatchPrediction';

export default function SectionFour() {
    const themes = useTheme();
    const {sectionFour,} = themes
  return (
    <Box component={"section"} sx={sectionFour.box1} bgcolor={"#5864ff1a"} className='wabSectionFour'>
        <Container>
            <Grid container alignItems={"center"}>
                <Grid item xs={12}>
                    <Box>
                        <Typography sx={sectionFour.grid2Box1Typo1}>
                            Easy to Use with Tons of Awesome Features
                        </Typography>
                        <Typography sx={sectionFour.grid2Box1Typo2}>
                            WABlaster is your ultimate solution for seamless, efficient, and feature-packed messaging. Whether you're managing customer relationships, running marketing campaigns, or simply staying in touch with your audience
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sx={sectionFour.grid2}>
                    <Box>
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={6} md={4} >
                                        <Box sx={sectionFour.grid2Box1}>
                                            <Box>
                                                <WhatsAppIcon sx={sectionFour.grid2Icon}/>
                                            </Box>
                                            <Box>
                                                <Typography sx={sectionFour.grid2TypoTitle} component="h3">
                                                    Effortless Messaging
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Typography sx={sectionFour.grid2TypoContent}>
                                                Send messages with ease using WABlaster’s intuitive interface. Whether it’s a single message or a bulk dispatch, our platform ensures your communications are delivered promptly and accurately.
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6} md={4}>
                                        <Box sx={sectionFour.grid2Box1}>
                                            <Box>
                                                <BookmarksIcon sx={sectionFour.grid2Icon}/>
                                            </Box>
                                            <Box>
                                                <Typography sx={sectionFour.grid2TypoTitle} component="h3">
                                                    Template Generation
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Typography sx={sectionFour.grid2TypoContent}>Create personalized and engaging messages with our advanced template generation feature. Customize templates to fit your brand’s voice and style, ensuring each message resonates with your audience.</Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6} md={4}>
                                        <Box sx={sectionFour.grid2Box1}>
                                            <Box>
                                                <AccountTreeIcon sx={sectionFour.grid2Icon}/>
                                            </Box>
                                            <Box>
                                                <Typography sx={sectionFour.grid2TypoTitle} component="h3">
                                                    CRM Integrations
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Typography sx={sectionFour.grid2TypoContent}>Integrate WABlaster with your existing CRM systems to streamline your workflow. Synchronize contacts, track conversations, and manage customer interactions all in one place, enhancing your customer relationship management.</Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6} md={4} >
                                        <Box sx={sectionFour.grid2Box1}>
                                            <Box>
                                                <BatchPredictionIcon sx={sectionFour.grid2Icon}/>
                                            </Box>
                                            <Box>
                                                <Typography sx={sectionFour.grid2TypoTitle} component="h3">
                                                    Batch Contact 
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Typography sx={sectionFour.grid2TypoContent}>Save time and increase productivity by sending messages in batches. WABlaster allows you to group contacts and send targeted messages to multiple recipients simultaneously, making it perfect for campaigns and updates.</Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6} md={4}>
                                        <Box sx={sectionFour.grid2Box1}>
                                            <Box>
                                                <LaptopWindowsIcon sx={sectionFour.grid2Icon}/>
                                            </Box>
                                            <Box>
                                                <Typography sx={sectionFour.grid2TypoTitle} component="h3">
                                                    User-Friendly Interface
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Typography sx={sectionFour.grid2TypoContent}>Navigate through WABlaster with ease, thanks to its user-friendly design. Whether you're a tech-savvy professional or new to messaging platforms, WABlaster is designed to be accessible and straightforward.</Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6} md={4}>
                                        <Box sx={sectionFour.grid2Box1}>
                                            <Box>
                                                <SupportAgentIcon sx={sectionFour.grid2Icon}/>
                                            </Box>
                                            <Box>
                                                <Typography sx={sectionFour.grid2TypoTitle} component="h3">
                                                    24/7 Support
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Typography sx={sectionFour.grid2TypoContent}>Our dedicated support team is available around the clock to assist you with any queries or issues. Experience unparalleled customer service and support with WABlaster.</Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    </Box>
  )
}
