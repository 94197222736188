import React from 'react'
import { useTheme } from '@mui/material/styles';
import { Container,Box, Grid, Typography, Button } from '@mui/material';
import RightAboutshape from '../../assets/image/aboutrightshape.svg'
import Rightdots from '../../assets/image/rightdots.svg'
import Image2 from '../../assets/image/splash2.png'
import CircleIcon from '@mui/icons-material/Circle';

export default function SectionSecond() {
    const themes = useTheme();
    const {sectionThree,} = themes

    const goToexe = () =>{
        window.open('https://chromewebstore.google.com/detail/wablasters-bulk-wa-messag/hmfggmookomommnebacphkkignbollcm', '_blank');
    }
    
    return (
        <Box component={"section"} mt={16} className='wabSectionSecond'>
            <Container>
                <Grid container alignItems={"center"}>
                    <Grid item xs={12} sm={6} md={6} sx={sectionThree.grid2}>
                        <Box>
                            <Typography sx={sectionThree.grid2Typo1}>
                                Generate Message Template With AI
                            </Typography>
                            <Typography sx={sectionThree.grid2Typo2}>
                                Introducing our AI-Powered Message Template feature! Easily create personalized, engaging messages tailored to your audience
                            </Typography>
                        </Box>
                        <Box sx={sectionThree.box2}>
                            <Grid container alignItems={"center"}>
                                <Grid item xs={12}>
                                    <Box>
                                        <Typography>
                                            <CircleIcon className='wabSTIcon'/>
                                            <Typography  sx={sectionThree.box2GridTypo} component={"span"}>Quick Access</Typography>
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box>
                                        <Typography>
                                            <CircleIcon className='wabSTIcon'/>
                                            <Typography sx={sectionThree.box2GridTypo} component={"span"}>Easily to Manage</Typography>
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box>
                                        <Typography>
                                            <CircleIcon className='wabSTIcon'/>
                                            <Typography sx={sectionThree.box2GridTypo} component={"span"}>24/7 Support</Typography>
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box sx={sectionThree.box2}>
                            <Button sx={sectionThree.grid2Btn} onClick={()=>goToexe()} variant="outlined">Get Started</Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Box sx={sectionThree.box1}>
                            <img src={Image2} className='shapImage'/>
                            <img src={RightAboutshape} className='shap shap3'/>
                            <img src={Rightdots} className='shap shap4'/>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}
