import React, { useState } from 'react'
import { useTheme } from '@mui/material/styles';
import { Container,Box, Grid, Typography, Button } from '@mui/material';
import Image1 from '../../assets/image/1.png'
import Image2 from '../../assets/image/2.png'
import SectionSecond from './SectionSecond';
import SectionThree from './SectionThree';
import SectionFour from './SectionFour';
import SectionFifth from './SectionFifth';
import TextField from '@mui/material/TextField';
import CustomSnackBar from '../../component/CustomSnackBar'
import {priceWaitlistData} from '../../utils/background'
import { useNavigate } from 'react-router-dom';

export default function Home() {
  const themes = useTheme();
  const {sectionOne,appBar} = themes
  const [message,setMessage] = useState('Thankyou for choice WABlaster')
  const [openSnackbar,setopenSnackbar] = useState(false)
  const [email,setemail] = useState('')
  const navigate = useNavigate()



  const routeHandler = (url) =>{
    if (url!=''){
      navigate(url)
    }
  } 

  const registerWaitlistUser = async() =>{
    if (email!=''){
      let response = await priceWaitlistData(email)
      const {status} = response
      if (status === 200){
        setopenSnackbar(true)
        setemail('')
      }
    }
  }

  const emailAddressHandler = (e) =>{
    const {value} = e.target
    setemail(value)
  } 

  const goToexe = () =>{
    window.open('https://chromewebstore.google.com/detail/wablasters-bulk-wa-messag/hmfggmookomommnebacphkkignbollcm', '_blank');
  }

  return (
    <>
      <Box component={"section"} sx={sectionOne.containerSX} className='wabSectionOne'>
        <Container>
          <Box sx={sectionOne.box2SX}>
            <Grid container>
              <Grid item xs={12} sm={12} md={5}>
                <Box>
                  <Grid container>
                    <Grid item xs={12} >
                      <Box>
                        <Typography component='h1'>
                          <Typography sx={sectionOne.webSection1}  component='span'className='webSection1TitleE'>Enlarge</Typography>
                          <Typography sx={sectionOne.webSection1} ml={2} component='span' className='webSection1TitleY'>Your</Typography>
                        </Typography>
                        <Typography component='h2'>
                          <Typography sx={sectionOne.webSection1} lineHeight={"64px"} color={"#0a6375"} component='span'className='webSection1TitleB'>Business</Typography>
                        </Typography>
                        <Typography component='h2'>
                          <Typography sx={sectionOne.webSection1} component='span'className='webSection1TitleB'>& Grow With Us</Typography>
                        </Typography>
                        <Typography component='p' sx={sectionOne.webSection1p}>
                          Connect with your customers. send personalised broadcast messages using Excel, Images with caption, Auto-reply, Download contacts & more...
                        </Typography>
                      </Box>
                      <Box mt={2}>
                        <Grid container>
                          <Grid item={6}>
                            <Button sx={appBar.btn} onClick={()=>{goToexe()}} style={{marginRight:"22px",backgroundColor:"#0a6375"}}>Try for Free</Button>
                            <Button sx={appBar.btn} onClick={()=>{routeHandler('/pricing')}} style={{backgroundColor:"#f6b7ab",color:"#000"}}>Get Premium</Button>
                            {/* <Box>
                              <TextField sx={sectionOne.textField} className='webBlasterWait' id="outlined-basic" placeholder="Email address" value={email} onChange={(e)=>{emailAddressHandler(e)}} variant="outlined" />
                              <Button onClick={()=>registerWaitlistUser()} sx={sectionOne.btn} style={{backgroundColor:"#f6b7ab",color:"#000"}}>Get price</Button>
                            </Box> */}
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid> 
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
              <Box sx={sectionOne.webSection1ImageBox}>
                <Grid container>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box>
                      <img className='webSection1Image webSection1Image1' src={Image1}/>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box sx={sectionOne.webSection1Image2}>
                      <img className='webSection1Image webSection1Image2' src={Image2}/></Box>
                  </Grid>
                </Grid>
              </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <SectionSecond/>
      <SectionThree/>
      <SectionFour/>
      <SectionFifth/>
      <CustomSnackBar message={message} open={openSnackbar} hideSnackbar={()=>{setopenSnackbar(false)}}/>
    </>
  )
}
