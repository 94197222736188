import React from 'react'
import {Box,Container, Grid, Typography,Link} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useHref, useNavigate} from 'react-router-dom'
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const socialMenu = [
  {icon:<InstagramIcon fontSize="large"/>,to:"https://www.instagram.com/wablastersapp/"},
  {icon:<FacebookIcon fontSize="large"/>,to:"https://www.facebook.com/wablasterapp"},
  {icon:<TwitterIcon fontSize="large"/>,to:"https://x.com/wablasters"},
  {icon:<LinkedInIcon fontSize="large"/>,to:"https://www.linkedin.com/company/wablasters"},
]

const sxStyle = {
  '&:hover':{
    borderBottom:"1px solid"
  }
}

export default function Footer() {
  const themes = useTheme();
  const navigate  = useNavigate()
  const parms     = useHref()

  let  footerMenu = [
    {name:"About us",to:"/about-us"},
    {name:"Privacy",to:"/privacy"},
    {name:"Terms",to:"/terms"},
    {name:"Return & Refund",to:"/return-refund"},
    {name:"Support",to:"/support"},
    {name:"Faqs",to:"/faqs"},
  ]
  // footerMenu = footerMenu.filter((menu)=>menu.to!== parms)
  const matches = useMediaQuery('(max-width:767px)');

  return (
    <Container maxWidth={"lg"} xs={12} sm={1} sx={{mt:7}} component={"footer"} >
        <Box className='footerContainer' pt={3} pb={3} borderTop={"2px solid #c4c1c1"} sx={{borderTopStyle:"dashed"}}>
            <Grid container flexWrap={"wrap"}>
              <Grid item xs={12} sm={2.5} sx={matches?themes.term.footerItem:{width:100}}>
                <Typography fontWeight={600} color={"#00a884"} fontSize={"35px"} fontFamily={'nunito, sans-serif'}  component={"span"}>WA</Typography>
                <Typography fontWeight={600} color={"#000"} fontSize={"35px"} fontFamily={'nunito, sans-serif'}  component={"span"}>Blasters</Typography>
              </Grid>
              <Grid item xs={12} sm={6} alignItems={"center"} display={"flex"} sx={matches?themes.term.footerItem:{width:100}}>
                <Grid container justifyContent={matches?"center":"center"}>
                  {
                    footerMenu.map((menu,index)=>{
                      return(
                        <Grid onClick={()=>{navigate(menu.to);window.scrollTo({ top:0,behavior:'smooth'})}} sx={{cursor:"pointer"}} key={index}  pr={1} pl={1} item style={{maxWidth:"fit-content"}}  xs={12} md={3}>
                          <Typography sx={sxStyle} fontFamily={'nunito, sans-serif'} fontSize={"14px"} color={"#000"}>{menu.name}</Typography>
                        </Grid>
                      )
                    })
                  }
                </Grid>
              </Grid>
              <Grid item xs={12} sm={3} alignItems={"center"} display={"flex"}  sx={matches?themes.term.footerItem:{width:100}}>
                <Grid container  justifyContent={matches?"center":"end"}>
                    {
                      socialMenu.map((menu,index)=>{
                        return(
                          <Grid sx={{cursor:"pointer"}}  key={index}  pr={1} pl={1} item style={{maxWidth:"fit-content"}}  xs={3}>
                            <Link href={menu.to} target="_blank" color="inherit">{menu.icon}</Link>
                          </Grid>
                        )
                      })
                    }
                </Grid>
              </Grid>
            </Grid>
        </Box>
    </Container>
  )
}
