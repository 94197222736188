import React from 'react'
import { useTheme } from '@mui/material/styles';
import { Container,Box, Grid, Typography,Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';


const menuList = [
  {id:1,name:"About us",to:"/about-us"},
  {id:2,name:"Privacy",to:"/privacy"},
  {id:3,name:"Terms",to:"/terms"},
  {id:4,name:"Return & Refund",to:"/return-refund"},
  {id:5,name:"Support",to:"/support"},
  {id:6,name:"Faqs",to:"/faqs"},
]

export default function Footer() {
  const themes = useTheme();
  const {footer,appBar} = themes
  const navigate = useNavigate()
  const socialIconList = [
    {id:1,icon:<InstagramIcon sx={footer.mapGridSocial}/>,to:"https://www.instagram.com/wablastersapp/"},
    {id:2,icon:<FacebookIcon sx={footer.mapGridSocial}/>,to:"https://www.facebook.com/wablasterapp"},
    {id:3,icon:<TwitterIcon sx={footer.mapGridSocial}/>,to:"https://x.com/wablasters"},
    {id:4,icon:<LinkedInIcon sx={footer.mapGridSocial}/>,to:"https://www.linkedin.com/company/wablasters/"},
  ]

  const routeHandler = (url) =>{
    if (url!=''){
      navigate(url)
    }
  } 

  return (
    <Box component={"footer"} className='wabfooter' bgcolor={"#171e30"}>
        <Container sx={footer.contaniner}>
          <Grid container>
            <Grid item xs={12}>
              <Box>
                <Grid container>
                  <Grid item xs={12} md={2}>
                    <Box>
                      <Typography sx={footer.copyRight} >© WABlaster, 2024</Typography>
                    </Box>    
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Box>
                      <Grid container  sx={footer.gridContaniner}>
                        {menuList.map((menu,index)=>{
                          return(
                            <Grid  key={index} sx={footer.mapGrid}  item xs={12} md={menu.id===4?2:1.1} >
                                <Box onClick={()=>{routeHandler(menu.to)}}>
                                  <Typography sx={footer.mapGridTypo} >{menu.name}</Typography>
                                </Box>
                            </Grid>
                          )
                        })}
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Box>
                      <Grid container  sx={footer.gridContaniner}>
                        {socialIconList.map((menu,index)=>{
                          return(
                            <Grid  key={index} sx={footer.mapGrid}  item xs={2}>
                                <Box>
                                  <Link href={menu.to} target="_blank" color="inherit">{menu.icon}</Link>
                                </Box>
                            </Grid>
                          )
                        })}
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Container>
    </Box>
  )
}
