import React,{useState} from 'react'
import { useTheme } from '@mui/material/styles';
import { Container,Box, Grid, Typography, Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import {priceWaitlistData} from '../../utils/background'
import CustomSnackBar from '../../component/CustomSnackBar'

export default function SectionFifth() {
    const themes = useTheme();
    const {sectionFifth,appBar} = themes
    const [message,setMessage] = useState('Thankyou for choice WABlaster')
    const [openSnackbar,setopenSnackbar] = useState(false)
    const [email,setemail] = useState('')

    const registerWaitlistUser = async() =>{
        if (email!=''){
        let response = await priceWaitlistData(email)
        const {status} = response
        if (status === 200){
            setopenSnackbar(true)
            setemail('')
        }
        }
    }

  const emailAddressHandler = (e) =>{
    const {value} = e.target
    setemail(value)
  }
  
  return (
    <>
    <Box component={"section"} sx={sectionFifth.box1} className='wabSectionFifth'>
        <Container>
            <Box sx={sectionFifth.box2}>
                <Grid container alignItems={"center"}>
                    <Grid item xs={12}>
                        <Box>
                            <Grid container alignItems={"center"}>
                                <Grid item xs={12} md={6}>
                                    <Box>
                                        <Typography sx={sectionFifth.typo}>Get the Latest Price List</Typography>
                                    </Box>
                                    <Box>
                                        <Typography sx={sectionFifth.content}>
                                            We believe our new products will meet your needs and exceed your expectations. Stay tuned for more updates as we get closer to the launch date.
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6} sx={sectionFifth.grid}>
                                    <Grid container>
                                        <Grid item xs={12} md={8.7} sx={sectionFifth.inputBox}>
                                            <Box>
                                                <TextField value={email} onChange={(e)=>{emailAddressHandler(e)}} className='wabwaitlist' fullWidth placeholder='Email address' id="fullWidth" />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={3} sx={sectionFifth.btnBox}>
                                            <Box ml={0.2}>
                                                <Button onClick={()=>registerWaitlistUser()} sx={[appBar.btn,sectionFifth.btn]}>Get price</Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    </Box>
    <CustomSnackBar message={message} open={openSnackbar} hideSnackbar={()=>{setopenSnackbar(false)}}/>
    </>
  )
}
