import React from 'react'
import { Container,Box, Grid, Typography} from '@mui/material';
import { useTheme } from '@mui/material/styles';

export default function ReturnRefund() {
    const themes = useTheme();
    const {about} = themes

    return (
        <Container>
            <Box>
                <Grid container>
                    <Grid item xs={12} sx={about.grid2}>
                       <Typography sx={about.Typo1}>
                            We only offer digital products so no refund cancellation or returns are possible after an order has been placed. If you have a continuing subscription then you can cancel anytime and no charges will be deducted after you cancel the subscription/membership.
                            However any charges deducted before cancellation of membership cannot be refunded!
                        </Typography>
                        <Typography sx={about.Typo3}>
                        For any other questions regarding cancellation or refunds send us an email at <Typography sx={about.Typo2} component={"a"}target='blank'>support@wablasters.com</Typography>
                        </Typography> 
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}
