import React from 'react'
import { Container,Box, Grid, Typography, List, ListItem, ListItemIcon, ListItemText, Link} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

export default function PrivacyPolicy() {
    const themes = useTheme();
    const {global} = themes
    return (
        <Container>
            <Box>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography sx={global.termTitle}>Last updated: Jul 30, 2024</Typography>
                        <Typography sx={global.termContent}>This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collected in WABlasters.</Typography>
                        <Typography sx={global.termContent}>This policy is not applicable to any information collected offline or via channels other than this website.</Typography>
                    </Grid>
                    
                    <Grid item xs={12}>
                        <Typography sx={global.termTitle}>CONSENT</Typography>
                        <Typography sx={global.termContent}>By accessing our website, you agree to comply with our Privacy Policy and accept its terms.</Typography>
                        <Typography sx={global.termContent}>The WABlasters team developed the Chrome extension as a commercial application. This SERVICE is provided by WABlasters and is intended for use in its current form.</Typography>
                        <Typography sx={global.termContent}>This page is designed to inform website visitors about our policies concerning the collection, use, and disclosure of Personal Information for anyone who decides to use our Service.</Typography>
                        <Typography sx={global.termContent}>By choosing to use our Service, you consent to the collection and use of information as outlined in this policy. The Personal Information we collect is utilized to provide and enhance the Service. We will not use or share your information with anyone except as described in this Privacy Policy.</Typography>
                        <Typography sx={global.termContent}>The terms used in this Privacy Policy have the same meanings as those in our Terms and Conditions, which are accessible at WABlasters, unless otherwise specified in this Privacy Policy.</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography sx={global.termTitle}>Information we collect</Typography>
                        <Typography sx={global.termContent}>When we request your personal information, we will clearly explain what information is needed and why it is necessary.</Typography>
                        <Typography sx={global.termContent}>Should you contact us directly, we may collect additional information about you, including your name, email address, phone number, the content of your message and any attachments you send, as well as any other information you choose to share.</Typography>
                        <Typography sx={global.termContent}>During the registration process for an Account, we may request contact information such as your name, company name, address, email address, phone number, as well as cookies and usage data.</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography sx={global.termTitle}>How we use your information</Typography>
                        <Typography sx={global.termContent}>
                            <List component={'ul'} sx={{marginTop:0}}>
                                <ListItem sx={{...global.termContent, padding:0, marginTop:0, display: "flex", alignItems: "flex-start"}}>
                                    <ListItemIcon sx={{paddingTop:"4px"}}><KeyboardArrowRightIcon /></ListItemIcon>
                                    <ListItemText>Providing, operating, and maintaining our website</ListItemText>
                                </ListItem>
                                <ListItem sx={{...global.termContent, padding:0, marginTop:0, display: "flex", alignItems: "flex-start"}}>
                                    <ListItemIcon sx={{paddingTop:"4px"}}><KeyboardArrowRightIcon /></ListItemIcon>
                                    <ListItemText>Improving, personalizing, and expanding our website</ListItemText>
                                </ListItem>
                                <ListItem sx={{...global.termContent, padding:0, marginTop:0, display: "flex", alignItems: "flex-start"}}>
                                    <ListItemIcon sx={{paddingTop:"4px"}}><KeyboardArrowRightIcon /></ListItemIcon>
                                    <ListItemText>Understanding and analyzing how you use our website</ListItemText>
                                </ListItem>
                                <ListItem sx={{...global.termContent, padding:0, marginTop:0, display: "flex", alignItems: "flex-start"}}>
                                    <ListItemIcon sx={{paddingTop:"4px"}}><KeyboardArrowRightIcon /></ListItemIcon>
                                    <ListItemText>Developing new products, services, features, and functionality</ListItemText>
                                </ListItem>
                                <ListItem sx={{...global.termContent, padding:0, marginTop:0, display: "flex", alignItems: "flex-start"}}>
                                    <ListItemIcon sx={{paddingTop:"4px"}}><KeyboardArrowRightIcon /></ListItemIcon>
                                    <ListItemText>Communicating with you, either directly or through one of our partners, for purposes such as customer service, updates, marketing, and promotions</ListItemText>
                                </ListItem>
                                <ListItem sx={{...global.termContent, padding:0, marginTop:0, display: "flex", alignItems: "flex-start"}}>
                                    <ListItemIcon sx={{paddingTop:"4px"}}><KeyboardArrowRightIcon /></ListItemIcon>
                                    <ListItemText>Sending you emails</ListItemText>
                                </ListItem>
                                <ListItem sx={{...global.termContent, padding:0, marginTop:0, display: "flex", alignItems: "flex-start"}}>
                                    <ListItemIcon sx={{paddingTop:"4px"}}><KeyboardArrowRightIcon /></ListItemIcon>
                                    <ListItemText>Detecting and preventing fraud</ListItemText>
                                </ListItem>
                                <ListItem sx={{...global.termContent, padding:0, marginTop:0, display: "flex", alignItems: "flex-start"}}>
                                    <ListItemIcon sx={{paddingTop:"4px"}}><KeyboardArrowRightIcon /></ListItemIcon>
                                    <ListItemText>Using cookies to keep users logged into the extension seamlessly</ListItemText>
                                </ListItem>
                                <ListItem sx={{...global.termContent, padding:0, marginTop:0, display: "flex", alignItems: "flex-start"}}>
                                    <ListItemIcon sx={{paddingTop:"4px"}}><KeyboardArrowRightIcon /></ListItemIcon>
                                    <ListItemText>Utilizing the Google Sheets API to add your tag data to a specified Google Sheet</ListItemText>
                                </ListItem>
                                <ListItem sx={{...global.termContent, padding:0, marginTop:0, display: "flex", alignItems: "flex-start"}}>
                                    <ListItemIcon sx={{paddingTop:"4px"}}><KeyboardArrowRightIcon /></ListItemIcon>
                                    <ListItemText>Employing the Google Calendar API to add events to your Google Calendar without leaving the WABlasters extension</ListItemText>
                                </ListItem>
                            </List>
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography sx={global.termTitle}>Log Files</Typography>
                        <Typography sx={global.termContent}>WABlasters uses log files as part of its standard procedures. These files record visitors when they access websites, a common practice among hosting companies as part of their analytics services. The information collected by log files includes internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamps, referring and exit pages, and potentially the number of clicks. This information is not linked to any personally identifiable information. The purpose of collecting this data is to analyze trends, administer the site, track users' movements on the website, and gather demographic information.</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography sx={global.termTitle}>Cookies and Web Beacons</Typography>
                        <Typography sx={global.termContent}>Like many other websites, WABlasters uses cookies to enhance user experience. These cookies store information about visitors' preferences and the pages they visit on the site. The data collected is used to optimize users' experience by customizing web page content based on visitors' browser type and other relevant information.</Typography>
                        <Typography sx={global.termContent}>For more general information on cookies, please refer to "What Are Cookies" from Cookie Consent.</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography sx={global.termTitle}>Advertising Partners Privacy Policies</Typography>
                        <Typography sx={global.termContent}>You can refer to this list to find the Privacy Policy for each of WABlasters's advertising partners. Third-party ad servers or ad networks use technologies such as cookies, JavaScript, or Web Beacons in their advertisements and links that appear on WABlasters, which are sent directly to your browser. When this happens, they automatically receive your IP address. These technologies are employed to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content you see on the websites you visit.</Typography>
                        <Typography sx={global.termContent}>Please note that WABlasters does not have access to or control over these cookies used by third-party advertisers.</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography sx={global.termTitle}>Third-Party Privacy Policies</Typography>
                        <Typography sx={global.termContent}>WABlasters's Privacy Policy does not apply to other advertisers or websites. Therefore, we recommend that you review the Privacy Policies of these third-party ad servers for more detailed information. These policies may include their practices and instructions on how to opt out of certain options.</Typography>
                        <Typography sx={global.termContent}>You can manage cookie preferences through your browser settings. For more detailed information about managing cookies with specific web browsers, please refer to the browsers' respective websites.</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography sx={global.termTitle}>Security</Typography>
                        <Typography sx={global.termContent}>We value your trust in providing us with your Personal Information, and we strive to use commercially acceptable means to protect it. However, please remember that no method of transmission over the Internet or electronic storage is completely secure and reliable, and we cannot guarantee absolute security.</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography sx={global.termTitle}>CCPA Privacy Rights (Do Not Sell My Personal Information)</Typography>
                        <Typography sx={global.termContent}>Under the California Consumer Privacy Act (CCPA), California consumers have the following rights:</Typography>
                        <Typography sx={global.termContent}>
                            <List component={'ul'} sx={{marginTop:0}}>
                                <ListItem sx={{...global.termContent, padding:0, marginTop:0, display: "flex", alignItems: "flex-start"}}>
                                    <ListItemIcon sx={{paddingTop:"4px"}}><KeyboardArrowRightIcon /></ListItemIcon>
                                    <ListItemText><b>Request Disclosure:</b> Consumers can request that a business disclose the categories and specific pieces of personal data it has collected about them.</ListItemText>
                                </ListItem>
                                <ListItem sx={{...global.termContent, padding:0, marginTop:0, display: "flex", alignItems: "flex-start"}}>
                                    <ListItemIcon sx={{paddingTop:"4px"}}><KeyboardArrowRightIcon /></ListItemIcon>
                                    <ListItemText><b>Request Deletion:</b> Consumers can request that a business delete any personal data it has collected about them.</ListItemText>
                                </ListItem>
                                <ListItem sx={{...global.termContent, padding:0, marginTop:0, display: "flex", alignItems: "flex-start"}}>
                                    <ListItemIcon sx={{paddingTop:"4px"}}><KeyboardArrowRightIcon /></ListItemIcon>
                                    <ListItemText><b>Opt-Out of Sale:</b> Consumers can request that a business not sell their personal data.</ListItemText>
                                </ListItem>
                            </List>
                        </Typography>
                        <Typography sx={global.termContent}>If you would like to exercise any of these rights, please contact us. We have one month to respond to your request.</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography sx={global.termTitle}>GDPR Data Protection Rights</Typography>
                        <Typography sx={global.termContent}>We want to ensure you are fully informed about your data protection rights. Each user is entitled to the following rights:</Typography>
                        <Typography sx={global.termContent}>
                            <List component={'ul'} sx={{marginTop:0}}>
                                <ListItem sx={{...global.termContent, padding:0, marginTop:0, display: "flex", alignItems: "flex-start"}}>
                                    <ListItemIcon sx={{paddingTop:"4px"}}><KeyboardArrowRightIcon /></ListItemIcon>
                                    <ListItemText><b>Right to Access:</b> You have the right to request copies of your personal data. We may charge a small fee for this service.</ListItemText>
                                </ListItem>
                                <ListItem sx={{...global.termContent, padding:0, marginTop:0, display: "flex", alignItems: "flex-start"}}>
                                    <ListItemIcon sx={{paddingTop:"4px"}}><KeyboardArrowRightIcon /></ListItemIcon>
                                    <ListItemText><b>Right to Rectification:</b> You have the right to request corrections to any inaccurate information we hold about you, as well as to request the completion of any incomplete data.</ListItemText>
                                </ListItem>
                                <ListItem sx={{...global.termContent, padding:0, marginTop:0, display: "flex", alignItems: "flex-start"}}>
                                    <ListItemIcon sx={{paddingTop:"4px"}}><KeyboardArrowRightIcon /></ListItemIcon>
                                    <ListItemText><b>Right to Erasure:</b> You have the right to request the deletion of your personal data under certain conditions.</ListItemText>
                                </ListItem>
                                <ListItem sx={{...global.termContent, padding:0, marginTop:0, display: "flex", alignItems: "flex-start"}}>
                                    <ListItemIcon sx={{paddingTop:"4px"}}><KeyboardArrowRightIcon /></ListItemIcon>
                                    <ListItemText><b>Right to Restrict Processing:</b> You have the right to request that we limit the processing of your personal data under certain conditions.</ListItemText>
                                </ListItem>
                                <ListItem sx={{...global.termContent, padding:0, marginTop:0, display: "flex", alignItems: "flex-start"}}>
                                    <ListItemIcon sx={{paddingTop:"4px"}}><KeyboardArrowRightIcon /></ListItemIcon>
                                    <ListItemText><b>Right to Object to Processing:</b> You have the right to object to the processing of your personal data under certain conditions.</ListItemText>
                                </ListItem>
                                <ListItem sx={{...global.termContent, padding:0, marginTop:0, display: "flex", alignItems: "flex-start"}}>
                                    <ListItemIcon sx={{paddingTop:"4px"}}><KeyboardArrowRightIcon /></ListItemIcon>
                                    <ListItemText><b>Right to Data Portability:</b> You have the right to request that we transfer your collected data to another organization or directly to you under certain conditions.</ListItemText>
                                </ListItem>
                            </List>
                        </Typography>
                        <Typography sx={global.termContent}>If you wish to exercise any of these rights, please contact us. We will respond to your request within one month.</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography sx={global.termTitle}>Children's Information</Typography>
                        <Typography sx={global.termContent}>Another of our priorities is ensuring the protection of children while they use the internet. We encourage parents and guardians to observe, participate in, and monitor their children's online activities</Typography>
                        <Typography sx={global.termContent}>WABlasters does not knowingly collect any personally identifiable information from children under the age of 13. If you believe that your child has provided such information on our website, please contact us immediately. We will make every effort to promptly remove the information from our records.</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography sx={global.termTitle}>Changes to this Privacy Policy</Typography>
                        <Typography sx={global.termContent}>We may update our Privacy Policy occasionally. Any changes will be communicated by posting the revised Privacy Policy on this page.</Typography>
                        <Typography sx={global.termContent}>We will also notify you via email and/or a prominent notice on our Service before the changes take effect, and we will update the "effective date" at the top of this Privacy Policy.</Typography>
                        <Typography sx={global.termContent}>We advise you to review this Privacy Policy periodically for any updates. Changes to the Privacy Policy take effect when they are posted on this page.</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography sx={global.termTitle}>Contact Information</Typography>
                        <Typography sx={global.termContent}><Link href="mailto:support@wablasters.com" underline="always">support@wablasters.com</Link></Typography>
                    </Grid>

                </Grid>    
            </Box>
        </Container>
    )
}
