import * as React from 'react';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';

export default function CustomSnackBar({message,open,hideSnackbar}) {

    setTimeout(()=>{
        if (open){
            handleClose()
        }
    },3000)
    const handleClose = () => {
        hideSnackbar()
    };

    return (
        <Box sx={{ width: 500 }}>
        <Snackbar
            anchorOrigin={{ vertical:"bottom", horizontal:"left" }}
            open={open}
            onClose={handleClose}
            message={message}
        />
        </Box>
    );
}
