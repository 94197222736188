import React from "react";
import { Container, Box, Grid, Typography, List, ListItem, ListItemIcon, ListItemText, Link } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

export default function TermsCondition() {
  const themes = useTheme();
  const { global } = themes;
  return (
    <Container>
        <Box>
            <Grid container>
                <Grid item xs={12}>
                    <Typography sx={global.termTitle}>Version 1.0</Typography>
                    <Typography sx={global.termTitle}>Last updated: Jul 30, 2024</Typography>
                    <Typography sx={global.termContent}>The WABlasters website located at https://www.wablasters.com is a copyrighted work. Certain features of the Site may be subject to additional guidelines, terms, or rules, which will be posted on the Site in connection with such features..</Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography sx={global.termTitle}>The Basics Of Buying A Telescope</Typography>
                    <Typography sx={global.termContent}>These Terms of Use outline the legally binding terms and conditions governing your use of the Site. BY LOGGING INTO THE SITE, YOU AGREE TO COMPLY WITH THESE TERMS and represent that you have the authority and capacity to enter into this agreement. YOU MUST BE AT LEAST 18 YEARS OLD TO ACCESS THE SITE. IF YOU DISAGREE WITH ANY PROVISION OF THESE TERMS, DO NOT LOG INTO OR USE THE SITE.</Typography>
                    <Typography sx={global.termContent}>These terms mandate the use of arbitration, as outlined in Section 10.2, on an individual basis to resolve disputes and limit the remedies available to you in the event of a dispute.</Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography sx={global.termTitle}>Access to the Site</Typography>
                    <Typography sx={global.termContent}><b>License Grant:</b><br/>Subject to these Terms, the Company grants you a non-transferable, non-exclusive, revocable, and limited license to access the Site solely for your personal, non-commercial use.</Typography>
                    <Typography sx={global.termContent}>
                        <b>Certain Restrictions</b><br/>The rights granted to you in these Terms are subject to the following restrictions:<br/>
                        <List component={'ul'} sx={{marginTop:0}}>
                            <ListItem sx={{...global.termContent, padding:0, marginTop:0, display: "flex", alignItems: "flex-start"}}>
                                <ListItemIcon sx={{paddingTop:"4px"}}><KeyboardArrowRightIcon /></ListItemIcon>
                                <ListItemText>You may not sell, rent, lease, transfer, assign, distribute, host, or otherwise commercially exploit the Site.</ListItemText>
                            </ListItem>
                            <ListItem sx={{...global.termContent, padding:0, marginTop:0, display: "flex", alignItems: "flex-start"}}>
                                <ListItemIcon sx={{paddingTop:"4px"}}><KeyboardArrowRightIcon /></ListItemIcon>
                                <ListItemText>You may not modify, create derivative works of, disassemble, reverse compile, or reverse engineer any part of the Site.</ListItemText>
                            </ListItem>
                            <ListItem sx={{...global.termContent, padding:0, marginTop:0, display: "flex", alignItems: "flex-start"}}>
                                <ListItemIcon sx={{paddingTop:"4px"}}><KeyboardArrowRightIcon /></ListItemIcon>
                                <ListItemText>You may not access the Site to build a similar or competitive website.</ListItemText>
                            </ListItem>
                            <ListItem sx={{...global.termContent, padding:0, marginTop:0, display: "flex", alignItems: "flex-start"}}>
                                <ListItemIcon sx={{paddingTop:"4px"}}><KeyboardArrowRightIcon /></ListItemIcon>
                                <ListItemText>Except as expressly stated in these Terms, no part of the Site may be copied, reproduced, distributed, republished, downloaded, displayed, posted, or transmitted in any form or by any means. Any future releases, updates, or other additions to the Site's functionality will also be subject to these Terms. All copyright and proprietary notices on the Site must be retained on all copies.</ListItemText>
                            </ListItem>
                        </List>
                    </Typography>
                    <Typography sx={global.termContent}><b>Site Changes and Termination:</b><br/>The Company reserves the right to change, suspend, or discontinue the Site with or without notice. You acknowledge that the Company will not be liable to you or any third party for any changes, interruptions, or termination of the Site or any part of it.</Typography>
                    <Typography sx={global.termContent}><b>No Support or Maintenance:</b><br/>You agree that the Company is not obligated to provide any support or maintenance in connection with the Site.</Typography>
                    <Typography sx={global.termContent}><b>Intellectual Property Rights:</b><br/>Excluding any User Content you may provide, you acknowledge that all intellectual property rights, including copyrights, patents, trademarks, and trade secrets in the Site and its content, are owned by the Company or the Company's suppliers. These Terms and your access to the Site do not grant you any rights, title, or interest in or to any intellectual property rights, except for the limited access rights described in Section 2.1. The Company and its suppliers reserve all rights not expressly granted in these Terms.</Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography sx={global.termTitle}>Third-Party Links & Ads</Typography>
                    <Typography sx={global.termContent}>The Site may contain links to third-party websites and services, as well as advertisements for third parties. These Third-Party Links & Ads are not under the control of the Company, and the Company is not responsible for any Third-Party Links & Ads. The Company provides access to these Third-Party Links & Ads solely for your convenience and does not review, approve, monitor, endorse, warrant, or make any representations with respect to them. You access and use all Third-Party Links & Ads at your own risk and should exercise caution and discretion. When you click on any Third-Party Links & Ads, the applicable third party's terms and policies, including privacy and data gathering practices, will apply.</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography sx={global.termTitle}>Other Users</Typography>
                    <Typography sx={global.termContent}>Each user of the Site is solely responsible for their own User Content. Because we do not control User Content, you acknowledge and agree that we are not responsible for any User Content, whether provided by you or others. You agree that the Company will not be liable for any loss or damage resulting from such interactions. If there is a dispute between you and another Site user, we are under no obligation to become involved.</Typography>
                    <Typography sx={global.termContent}>You hereby release and forever discharge the Company and its officers, employees, agents, successors, and assigns from, and hereby waive and relinquish, any and all past, present, and future disputes, claims, controversies, demands, rights, obligations, liabilities, actions, and causes of action of every kind and nature, that have arisen or arise directly or indirectly out of, or that relate directly or indirectly to, the Site. If you are a California resident, you hereby waive California Civil Code Section 1542 in connection with the foregoing, which states: "A general release does not extend to claims which the creditor does not know or suspect to exist in his or her favor at the time of executing the release, which, if known by him or her, must have materially affected his or her settlement with the debtor."</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography sx={global.termTitle}>Cookies and Web Beacons</Typography>
                    <Typography sx={global.termContent}>Like many other websites, WABlasters uses cookies. These cookies store information, including visitors' preferences and the pages they accessed or visited on the website. This information is used to enhance the user experience by customizing our web page content based on the visitor's browser type and other information.</Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography sx={global.termTitle}>Disclaimer of Warranties</Typography>
                    <Typography sx={global.termContent}>The Site is provided on an "as-is" and "as-available" basis. The Company and our suppliers expressly disclaim all warranties and conditions of any kind, whether express, implied, or statutory, including but not limited to, the warranties or conditions of merchantability, fitness for a particular purpose, title, quiet enjoyment, accuracy, and non-infringement. Neither we nor our suppliers guarantee that the Site will meet your requirements or that it will be available on an uninterrupted, timely, secure, or error-free basis. Additionally, we do not guarantee that the Site will be accurate, reliable, free of viruses or other harmful code, complete, legal, or safe. If applicable law requires any warranties with respect to the Site, all such warranties are limited in duration to ninety (90) days from the date of first use.</Typography>
                    <Typography sx={global.termContent}>Some jurisdictions do not allow the exclusion of implied warranties, so the above exclusion may not apply to you. Additionally, some jurisdictions do not allow limitations on how long an implied warranty lasts, so the above limitation may not apply to you.</Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography sx={global.termTitle}>Limitation of Liability</Typography>
                    <Typography sx={global.termContent}>To the fullest extent permitted by law, in no event shall the Company or our suppliers be liable to you or any third party for any lost profits, lost data, costs of procuring substitute products, or any indirect, consequential, exemplary, incidental, special, or punitive damages arising from or related to these Terms or your use of, or inability to use, the Site, even if the Company has been advised of the possibility of such damages. Access to and use of the Site is at your own discretion and risk, and you will be solely responsible for any damage to your device or computer system or loss of data resulting from such access and use.</Typography>
                    <Typography sx={global.termContent}>To the maximum extent permitted by law, and notwithstanding anything to the contrary contained herein, our liability to you for any damages arising from or related to this agreement will always be limited to a maximum of fifty U.S. dollars (USD $50). The existence of more than one claim will not increase this limit. You agree that our suppliers will have no liability whatsoever arising from or related to this agreement.</Typography>
                    <Typography sx={global.termContent}>Some jurisdictions do not allow the limitation or exclusion of liability for incidental or consequential damages, so the above limitation or exclusion may not apply to you.</Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography sx={global.termTitle}>Term and Termination</Typography>
                    <Typography sx={global.termContent}>Subject to this Section, these Terms will remain in full force and effect while you use the Site. We may suspend or terminate your rights to use the Site at any time, for any reason, and at our sole discretion, including for any use of the Site that violates these Terms. Upon termination of your rights under these Terms, your Account and your right to access and use the Site will be terminated immediately. You understand that any termination of your Account may involve deleting your User Content associated with your Account from our live databases. The Company will not be liable to you for any termination of your rights under these Terms. Even after your rights under these Terms have been terminated, the following provisions of these Terms will remain in effect: Sections 2 through 2.5, Section 3, and Sections 4 through 10.</Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography sx={global.termTitle}>Copyright Policy</Typography>
                    <Typography sx={global.termContent}>The Company respects the intellectual property rights of others and expects users of our Site to do the same. We have implemented a copyright policy in connection with our Site, which includes removing infringing materials and terminating accounts of users who repeatedly infringe on intellectual property rights, including copyrights. If you believe that a user of our Site is unlawfully infringing your copyright(s), and you wish to have the allegedly infringing material removed, you must provide the following information in the form of a written notification (in accordance with 17 U.S.C. § 512(c)) to our designated Copyright Agent:</Typography>
                    <Typography sx={global.termContent}>
                        <List component={'ul'} sx={{marginTop:0}}>
                            <ListItem sx={{...global.termContent, padding:0, marginTop:0, display: "flex", alignItems: "flex-start"}}>
                                <ListItemIcon sx={{paddingTop:"4px"}}><KeyboardArrowRightIcon /></ListItemIcon>
                                <ListItemText>Your physical or electronic signature.</ListItemText>
                            </ListItem>
                            <ListItem sx={{...global.termContent, padding:0, marginTop:0, display: "flex", alignItems: "flex-start"}}>
                                <ListItemIcon sx={{paddingTop:"4px"}}><KeyboardArrowRightIcon /></ListItemIcon>
                                <ListItemText>Identification of the copyrighted work(s) that you claim have been infringed.</ListItemText>
                            </ListItem>
                            <ListItem sx={{...global.termContent, padding:0, marginTop:0, display: "flex", alignItems: "flex-start"}}>
                                <ListItemIcon sx={{paddingTop:"4px"}}><KeyboardArrowRightIcon /></ListItemIcon>
                                <ListItemText>Identification of the material on our Site that you claim is infringing and that you request us to remove.</ListItemText>
                            </ListItem>
                            <ListItem sx={{...global.termContent, padding:0, marginTop:0, display: "flex", alignItems: "flex-start"}}>
                                <ListItemIcon sx={{paddingTop:"4px"}}><KeyboardArrowRightIcon /></ListItemIcon>
                                <ListItemText>Sufficient information to enable us to locate the infringing material.</ListItemText>
                            </ListItem>
                            <ListItem sx={{...global.termContent, padding:0, marginTop:0, display: "flex", alignItems: "flex-start"}}>
                                <ListItemIcon sx={{paddingTop:"4px"}}><KeyboardArrowRightIcon /></ListItemIcon>
                                <ListItemText>Your address, telephone number, and email address.</ListItemText>
                            </ListItem>
                            <ListItem sx={{...global.termContent, padding:0, marginTop:0, display: "flex", alignItems: "flex-start"}}>
                                <ListItemIcon sx={{paddingTop:"4px"}}><KeyboardArrowRightIcon /></ListItemIcon>
                                <ListItemText>A statement indicating that you have a good faith belief that the use of the objectionable material is not authorized by the copyright owner, its agent, or the law.</ListItemText>
                            </ListItem>
                            <ListItem sx={{...global.termContent, padding:0, marginTop:0, display: "flex", alignItems: "flex-start"}}>
                                <ListItemIcon sx={{paddingTop:"4px"}}><KeyboardArrowRightIcon /></ListItemIcon>
                                <ListItemText>A statement affirming that the information in the notification is accurate and, under penalty of perjury, that you are either the owner of the copyright that has allegedly been infringed or authorized to act on behalf of the copyright owner.</ListItemText>
                            </ListItem>
                        </List>
                    </Typography>
                    <Typography sx={global.termContent}>Please note that, according to 17 U.S.C. § 512(f), any misrepresentation of material fact in a written notification subjects the complaining party to liability for any damages, costs, and attorney's fees incurred by us in connection with the written notification and allegation of copyright infringement.</Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography sx={global.termTitle}>General</Typography>
                    <Typography sx={global.termContent}></Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography sx={global.termTitle}>Contact Information</Typography>
                    <Typography sx={global.termContent}><Link href="mailto:support@wablasters.com" underline="always">support@wablasters.com</Link></Typography>
                </Grid>
            </Grid>
        </Box>
    </Container>
  );
}
