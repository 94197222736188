import React from 'react'
import { Container,Box, Grid, Typography} from '@mui/material';
import { useTheme } from '@mui/material/styles';

export default function Support() {
    const themes = useTheme();
    const {about} = themes

    return (
        <Container>
            <Box>
                <Grid container justifyContent={"center"}>
                    <Grid item xs={12} md={8} sx={about.grid2}>
                       <Typography sx={about.Typo1}>
                            WhatsApp support available! Write to us at: <Typography sx={about.Typo2} component={"a"} href="mailto:support@wablasters.com">support@wablasters.com</Typography> to get a response with in 24 hours.
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}
