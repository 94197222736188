import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.js'
import { BrowserRouter } from 'react-router-dom';
import CustomTheme from './theme';
import './assets/style/index.css'
import { ThemeProvider } from '@mui/material/styles';;


ReactDOM.createRoot(document.getElementById('root')).render(
  <ThemeProvider theme={CustomTheme}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ThemeProvider>
)
