import React from 'react'
import { Container,Box, Grid, Typography, Accordion,AccordionSummary,AccordionDetails,Fade } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from '@mui/material/styles';

export default function Faqs() {
    const [expanded, setExpanded] = React.useState(false);
    const handleExpansion = () => {
        setExpanded((prevExpanded) => !prevExpanded);
    };
    const themes = useTheme();
    const {faqs,global} = themes

    return (
        <Container>
            <Box>
                <Grid container sx={faqs.grid1}>
                    <Grid item xs={12} md={10} >
                        <Accordion
                            expanded={expanded}
                            onChange={handleExpansion}
                            slots={{ transition: Fade }}
                            slotProps={{ transition: { timeout: 400 } }}
                            sx={{
                            '& .MuiAccordion-region': { height: expanded ? 'auto' : 0 },
                            '& .MuiAccordionDetails-root': { display: expanded ? 'block' : 'none' },
                            }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <Typography sx={[global.termTitle,{m:0}]} >How to send bulk messages?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography sx={[global.termContent,{mb:1}]} >
                                    Using our WhatApp bulk Sender, you could send bulk messages in 3 steps.
                                </Typography>
                                <Typography sx={[global.termContent,{m:0}]}>
                                    1. Choose your targetd WhatsApp users. The sender supports importing from Excel or adding from your groups.
                                </Typography>
                                <Typography sx={[global.termContent,{m:0}]}>
                                    2 .Edit Your Messages. You can add personalized greetings and attach files to make each message appear more authentic.
                                </Typography>
                                <Typography sx={[global.termContent,{m:0}]}>
                                    3. Click "Start sending ".
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2-content"
                                id="panel2-header"
                            >
                                <Typography sx={[global.termTitle,{m:0}]} >How many messages can I send at once?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography sx={[global.termContent,{m:0}]} >
                                    You can successfully send up to as per pricing messages at once
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel3-content"
                                id="panel3-header"
                            >
                                <Typography sx={[global.termTitle,{m:0}]}>What are the FREE features?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography sx={[global.termContent,{m:0}]} >
                                    1. Send 200 WhatsApp messages
                                </Typography>
                                <Typography sx={[global.termContent,{m:0}]} >
                                    2. Upload excel sheet and send to any country
                                </Typography>
                                <Typography sx={[global.termContent,{m:0}]} >
                                    3. Unlimted Batch messages
                                </Typography>
                                <Typography sx={[global.termContent,{m:0}]} >
                                    4. Unlimted Messages Template
                                </Typography>
                                <Typography sx={[global.termContent,{m:0}]} >
                                    5. CRM Integration
                                </Typography>
                                <Typography sx={[global.termContent,{m:0}]} >
                                    6. Download Group Contacts
                                </Typography>
                                <Typography sx={[global.termContent,{m:0}]} >
                                    7. Send pictures with captions
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel3-content"
                                id="panel3-header"
                            >
                                <Typography sx={[global.termTitle,{m:0}]}>Can I send pictures with captions?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography sx={[global.termContent,{m:0}]}>Yes</Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel4-content"
                                id="panel4-header"
                            >
                                <Typography sx={[global.termTitle,{m:0}]}>Do you support Portuguese and Spanish?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography sx={[global.termContent,{m:0}]}>Currently we support English, Portuguese and Spanish is planned.
                                </Typography>
                                <Typography sx={[global.termContent,{m:0}]}>By the way, if you need other languages, please contact customer service for feedback.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel5-content"
                                id="panel5-header"
                            >
                                <Typography sx={[global.termTitle,{m:0}]}>
                                How can we avoid banning?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography sx={[global.termContent,{m:0}]}>1.Do not send a tremendous amount of the same message. If you have to do this, please set a larger sending interval and add a timestamp at the end of the message, and most importantly we recommend that you send a personalized message
                                </Typography>
                                <Typography sx={[global.termContent,{m:0}]}>2.Try to avoid being reported by the recipient. After being reported by 10-15 people, the account will be permanently banned: follow WhatsApp rules and use WAPlus Sender reasonably, do not send spam messages and hard-sell advertising, it is recommended to add an apology option to let the recipient reply instead of reporting you. 
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel6-content"
                                id="panel6-header"
                            >
                                <Typography sx={[global.termTitle,{m:0}]}>Does it work in WhatsApp’s Desktop App or Mobile App?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography sx={[global.termContent,{m:0}]}>No, it is a chrome extension and it works only on WhatsApp web on Google Chrome (Mac, Windows, and Linux).
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}
