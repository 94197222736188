import MainLayout from '../layout/MainLayout';
import Home from '../page/home/Home'
import Pricing from '../page/home/Pricing'
import Success from '../page/Success';

const NotFound =()=>{
  return(
    <div>Not Found</div>
  )
}
const MainRoutes = {
  path: '/',
  element: (
    <MainLayout/>
  ),
  children: [
    {
      path: '/',
      element: <Home/>
    },
    {
      path: '/pricing',
      element: <Pricing/>
    },
    {
      path: '/success',
      element: <Success/>
    },
    {
      path: '*',
      element:<NotFound/>
    }
  ],
};

export default MainRoutes;
