import React from 'react'
import { useTheme } from '@mui/material/styles';
import { Container,Box, Grid } from '@mui/material';

export default function PageLayout({children}) {
  const themes = useTheme();
  const {pageLayout} = themes
  return (
      <Container sx={pageLayout.box1}>
        <Box>
          <Grid container>
            <Grid item xs={12}>
              {children}
            </Grid>
          </Grid>
        </Box>
      </Container>
  )
}
