import watlistImage from '../assets/image/waitlist.svg'
import { createTheme } from '@mui/material/styles';
let CustomTheme = createTheme()
let theme = CustomTheme


CustomTheme = createTheme({
    appBar:{
        header:{
            backgroundColor:"#e7fce3",
            boxShadow:"unset",
            padding: '16px 0px'
        },
        menuBox:{
            justifyContent:"center",
            flexGrow: 1, 
            display: {xs:'none',md:'flex'}
        },
        menu:{
            margin: '0px 30px',
            color:"#060f11",
            fontFamily: 'nunito, sans-serif',
            fontWeight:700,
            cursor: 'pointer',
            "&:hover":{
                borderBottom: '1px solid',
            }
        },
        btn:{
            background: '#00a884',
            color: '#fff',
            textTransform:'capitalize',
            borderRadius:'7px',
            fontFamily: 'nunito, sans-serif',
            fontWeight:700,
            padding:'6px 20px',
            "&:hover":{
                background: '#00a884',
            }
        },
        btnBox:{
            display: {xs:'none',md:'flex'}
        },
        headerDrawerMenu:{
            fontFamily:'nunito, sans-serif',
            fontWeight:700,
        }
    },
    sectionOne:{
        containerSX:{
            backgroundImage:"linear-gradient(0deg, #FFFFFF 0%, #e7fce3  100%)",
            [theme.breakpoints.between("xs","sm")]:{
                
            }, 
        },  
        box1SX:{},
        box2SX:{
            padding: '90px 0px 34px 0px',
            [theme.breakpoints.between("xs","md")]:{
                padding: '45px 0px 34px 0px',
            },  
        },
        webSection1:{
            fontSize:"45px",
            fontFamily:'nunito, sans-serif',
            [theme.breakpoints.between("xs","sm")]:{
                
            },    
        },
        webSection1p:{
            fontWeight:600,
            fontFamily:'nunito, sans-serif',
            [theme.breakpoints.between("xs","sm")]:{
                
            },    
        },
        webSection1ImageBox:{
            [theme.breakpoints.between("xs","md")]:{
                marginTop:"32px"
            },   
        },
        webSection1Image2:{
            marginTop:'103px',
            marginLeft:'-20px',
            [theme.breakpoints.between("xs","sm")]:{
                marginTop:'-92px',
                marginLeft:'136px',
            },
            [theme.breakpoints.between("sm","md")]:{
                marginTop:'208px',
                marginLeft:'106px',
            },
        },
        textField:{
            
        },
        btn:{
            background: '#00a884',
            color: '#fff',
            textTransform:'capitalize',
            borderRadius:'7px',
            fontFamily: 'nunito, sans-serif',
            fontWeight:700,
            padding:'10px 20px',
            marginLeft:'17px',
            [theme.breakpoints.between("xs","md")]:{
                marginLeft:'10px',
                marginTop:"0px"
            }, 
            "&:hover":{
                background: '#00a884',
            }
        },
    },
    sectionSecond:{
        box1:{
            position:'relative',
            zIndex: 1,
            padding:'75px 0',
            [theme.breakpoints.between("xs","md")]:{
                marginBottom:"70px"
            },
        },
        grid2:{ 
          paddingLeft:"70px",
          textAlign:"center",  
          [theme.breakpoints.between("xs","md")]:{
            paddingLeft:"20px"
        },
        },
        grid2Typo1:{
            fontSize:"26px",
            fontWeight: 700,
            textAlign: 'left',
            fontFamily:'nunito, sans-serif',
        },
        grid2Typo2:{
            fontSize: '18px',
            textAlign: 'left',
            marginTop: '10px',
            fontFamily:'nunito, sans-serif',
        },
        grid2Btn:{
            borderRadius:'15px',
            borderColor:'#00a884',
            padding: '10px 45px',
            color:'#00a884',
            textAlign: 'left',
            fontFamily:'nunito, sans-serif',
        },
        box2:{
            textAlign: 'left',
            marginTop:"30px"
        },
    },
    sectionThree:{
        box1:{
            position:'relative',
            zIndex: 1,
            padding:'75px 0',
        },
        gridRow:{
            direction:"column-reverse"
        },
        grid2:{
          paddingRight:"70px",
          textAlign:"center",
          [theme.breakpoints.between("xs","md")]:{
            paddingRight:"20px"
        },  
        },
        grid2Typo1:{
            fontSize:"26px",
            fontWeight: 700,
            textAlign: 'left',
            fontFamily:'nunito, sans-serif',
        },
        grid2Typo2:{
            fontSize: '18px',
            textAlign: 'left',
            marginTop: '10px',
            fontFamily:'nunito, sans-serif',
        },
        grid2Btn:{
            borderRadius:'15px',
            borderColor:'#00a884',
            padding: '10px 45px',
            color:'#00a884',
            textAlign: 'left',
            fontFamily:'nunito, sans-serif',
        },
        box2:{
            textAlign: 'left',
            marginTop:"30px"
        },
        box2GridTypo:{
            paddingLeft:'12px',
            position:'relative',
            top:'-6px',
        }

    },
    sectionFour:{
        box1:{
            paddingTop:"40px",
            paddingBottom:"15px",
            mt:16,
            [theme.breakpoints.between("xs","md")]:{
                mt:3,
            },
        },
        grid2Box1Typo1:{
            textAlign:'center',
            fontWeight: 700,
            fontSize:'25px',
            marginBottom:'25px',
            fontFamily:'nunito, sans-serif',
        },
        grid2Box1Typo2:{
            textAlign:'center',
            padding:"0px 358px",
            fontFamily:'nunito, sans-serif',
            [theme.breakpoints.between("xs","md")]:{
                padding:"0px",
            }, 
        },
        grid2:{
            marginTop:"20px",
            [theme.breakpoints.between("xs","md")]:{
                padding:"0px",
            }, 
        },
        grid2Box1:{
            padding:"30px",
            [theme.breakpoints.between("xs","md")]:{
                padding:"10px",
            },
        },
        grid2Icon:{
            fontSize:'70px',
            fontFamily:'nunito, sans-serif',
            marginBottom:"20px",
            color:"#00a884",
            [theme.breakpoints.between("xs","md")]:{
                fontSize:'50px',
                marginBottom:"15px",
            },
        },
        grid2TypoTitle:{
            fontWeight: 700,
            fontSize:'25px',
            fontFamily:'nunito, sans-serif',
            marginBottom:"20px",
            color:"#162447",
            [theme.breakpoints.between("xs","md")]:{
                fontSize:'20px',
                marginBottom:"15px",
            },
        },
        grid2TypoContent:{
            fontWeight: 400,
            fontSize:'18px',
            fontFamily:'nunito, sans-serif',
            color:"#5B657E",
            [theme.breakpoints.between("xs","md")]:{
                fontSize:'15px',
            },
        }
    },
    sectionFifth:{
        box1:{
            paddingTop:"40px",
            mt:2,
            mb:2,
            paddingBottom:"40px",
            [theme.breakpoints.between("xs","md")]:{
                mt:3,
            },
        },
        box2:{
            backgroundImage: `url(${watlistImage})`,
            padding:'65px 50px 50px',
            borderRadius:'30px', 
            [theme.breakpoints.between("xs","md")]:{
                padding: '40px 14px 13px',
            },
        },
        typo:{
            fontWeight:700,
            fontFamily:'nunito, sans-serif',
            marginBottom:"25px",
            fontSize:"30px",
            [theme.breakpoints.between("xs","md")]:{
                fontSize:"25px"
            },
        },
        content:{
            fontFamily:'nunito, sans-serif',
            fontSize:"18px"
        },
        btn:{
            padding:'15px 20px',  
        },
        grid:{
            [theme.breakpoints.between("xs","md")]:{
                marginTop: '40px',
            },
        },
        btnBox:{
            [theme.breakpoints.between("xs","md")]:{
                marginTop: '10px',
            },
        },
        inputBox:{
            [theme.breakpoints.between("xs","md")]:{
                marginTop: '10px',
            },
        },
    },
    footer:{
        contaniner:{
            padding:"60px"
        },
        copyRight:{
            fontSize:"16px",
            color:"#fff",
            fontFamily:'nunito, sans-serif',
        },
        gridContaniner:{
            justifyContent:"center",
            textAlign:"center",
            [theme.breakpoints.between("xs","md")]:{
                paddingTop:"20px",
                justifyContent:"left",
                textAlign:"left",
            },
        },
        mapGrid:{
            margin: '0px 5px'
        },
        mapGridTypo:{
            color:"#fff",
            fontSize:"16px",
            fontFamily:'nunito, sans-serif',
            cursor:"pointer",
            mt:"1px",
            "&:hover":{
                borderBottom:1,
            }
        },
        mapGridSocial:{
            fontFamily:'nunito, sans-serif',
            color:"#fff",
            cursor:"pointer",
        }

    },
    pageLayout:{
        box1:{
            backgroundColor: '#c2bfbf17',
            paddingTop:"90px",
            paddingBottom:"90px",
            borderRadius:"10px",
            mt:5,
            mb:5,
        },
    },
    faqs:{
        grid1:{
            justifyContent:"center",
            mt:10,
            [theme.breakpoints.between("xs","md")]:{
                mt:5,
            },
        }
    },
    about:{
        Typo1:{
            fontSize:"19px",
            fontFamily:'nunito, sans-serif',
            textAlign:"center",
            color: '#767676'
        },
        Typo2:{
            fontSize:"19px",
            fontFamily:'nunito, sans-serif',
            textDecoration:'none',
            color:"#00a884",
        },
        Typo3:{
            fontSize:"19px",
            fontFamily:'nunito, sans-serif',
            color: '#767676',
            padding: '10px 0px',
            textAlign:"center",
            mt:2,
            [theme.breakpoints.between("xs","md")]:{
                mt:0,
                padding: '0px',
            },
        },

        grid2:{
            mt:0,
        }
    },
    term:{
        header:{
            container:{
                padding:"10px",
                paddingLeft:"10px !important",
                paddingRight:"10px !important",
                [theme.breakpoints.between("xs","md")]: {
                    padding:"0px !important",
                },
            },
            box:{
                borderRadius:"8px",
                [theme.breakpoints.between("xs","md")]: {
                    borderRadius:"0px",
                },
            },
            title:{
                fontSize:"40px",
                color:"#f8cb46", 
                mt:6, 
                fontFamily:'nunito, sans-serif',
                [theme.breakpoints.between("xs","md")]: {
                    fontSize: "32px",
                    mt:4, 
                }
            }
        },
        footerItem:{
            maxWidth:"100% !important",
            minWidth:"100% !important",
            width:"100% !important",
            alignItems:"center",
            justifyContent:"center",
            textAlign:"center",
            padding:"15px"
        },
        content:{
            color:"#666666",
            fontSize: "18px",
            margin:"30px 40px",
            [theme.breakpoints.between("xs","md")]: {
                fontSize: "16px",
                margin:"10px"
            }
        },
        contentOption:{
            color:"#000",
            fontSize: "20px",
            margin:"10px 0px  10px 0px",
            [theme.breakpoints.between("xs","md")]: {
                fontSize: "18px",
                marginTop:"15px"
            }
        }
    },
    termsC:{
    },
    global:{
        termTitle:{
            mt:3,
            fontSize:"18px",
            fontWeight:700,
            fontFamily:'nunito, sans-serif',
        },
        termContent:{
            mt:1,
            fontSize:"16px",
            fontFamily:'nunito, sans-serif',
        },
        error:{
            fontSize: '13px',
            marginTop: '5px',
            color: 'red',
        }
    },
    pricing:{
        gridContainer1:{
            paddingTop:"50px",
            paddingBottom:"50px",
            justifyContent:"center"
        },
        gridContainerGrid2:{
            mt:6,
            mb:6
        },
        typo1:{
            textAlign:"center",
            fontSize:"30px",
            color:"#0a6375",
            fontFamily:'nunito, sans-seri'
        },
        card:{
            padding:"30px",
            borderRadius:"10px"
        },
        span:{
            fontSize:"17px",
            fontFamily:'nunito, sans-seri'
        },
        chip:{
            fontSize:"10px",
            fontFamily:'nunito, sans-seri',
            mb:0.5
        },
        price:{
            fontFamily:'nunito, sans-seri',
            fontSize: '30px',
            fontWeight: 700,
        },
        priceSpan:{
            ml:0.6,
            fontFamily:'nunito, sans-seri'
        },
        licenceTypo:{
            fontFamily:'nunito, sans-seri',
            mt:1.2
        },
        gridContainer3:{
            borderBottom:'2px solid #c4c1c1',
            borderBottomStyle:'dashed',
            mb:3
        },
        gridContainer3Box1:{
            mb:2.2
        },
        strike:{
            fontFamily:'nunito, sans-seri',
            fontSize: '30px',
            fontWeight: 700,
        },
        featureSpan:{
            position:'relative',
            top: '-5px',
            left:'14px',
            fontFamily:'nunito, sans-seri'
        },
        btnGrid:{
            textAlign:"center",
            mt:2
        },
        btn:{
            backgroundColor:"#ff4d4f",
            padding: '9px 140px',
            "&:hover":{
                backgroundColor:"#ff4d4f",
            },
            [theme.breakpoints.between("xs","md")]: {
                padding: '9px 73px',
            } 
        },
        modelBox1:{
            padding: '0px 20px 25px 20px',
            width: '350px',
            [theme.breakpoints.between("xs","sx")]: {
                width: '200px !important',
            } 
        },
        modelInputLabel:{
            fontFamily:'nunito, sans-seri',
            color:"#000",
            mb:1
        },
        btnContinue:{
            backgroundColor:"#00a884",
            "&:hover":{
                backgroundColor:"#00a884",
            },
            padding: '9px 134px',
        }
    },
    success:{
        container:{
            paddingTop:"85px",
            paddingBottom:"81px",
            [theme.breakpoints.between("xs","md")]:{
                paddingTop:"40px",
                paddingBottom:"40px",
            },
        },
        gridContainer:{
            justifyContent:"center",
            textAlign:"center"
        },
        typoBox:{
            padding:"35px",
            backgroundColor:"#00a884"
        },
        licenceBox:{
            padding:"40px",
            backgroundImage:'linear-gradient(0deg, #FFFFFF 0%, #e7fce3  100%)'
        },
        typo:{
            fontSize:"25px",
            color:"#fff",
            fontFamily:'nunito, sans-serif',
        }
    }
    
});
export default CustomTheme;