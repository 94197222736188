import React from 'react'
import { useTheme } from '@mui/material/styles';
import { Container,Box, Grid, Typography, Button } from '@mui/material';
import Aboutshape from '../../assets/image/aboutshape.svg'
import Leftdots from '../../assets/image/leftdots.svg'
import Image2 from '../../assets/image/splash1.png'

export default function SectionSecond() {
    const themes = useTheme();
    const {sectionSecond,appBar} = themes

    const goToexe = () =>{
        window.open('https://chromewebstore.google.com/detail/wablasters-bulk-wa-messag/hmfggmookomommnebacphkkignbollcm', '_blank');
    }

    return (
        <Box component={"section"} mt={2} className='wabSectionSecond'>
            <Container>
                <Grid container alignItems={"center"} >
                    <Grid item xs={12} sm={6} md={6}>
                        <Box sx={sectionSecond.box1}>
                            <img src={Image2} className='shapImage'/>
                            <img src={Aboutshape} className='shap shap1'/>
                            <img src={Leftdots} className='shap shap2'/>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} sx={sectionSecond.grid2}>
                        <Box>
                            <Typography sx={sectionSecond.grid2Typo1}> Easy to Use with Tons of Awesome Features</Typography>
                            <Typography sx={sectionSecond.grid2Typo2}>
                            The ultimate WA messaging solution! Effortlessly send individual or batch messages, create dynamic templates, and integrate seamlessly with your CRM. Enjoy a user-friendly interface, robust security, and comprehensive analytics, all backed by 24/7 support. Elevate your communication strategy with WABlaster today!
                            </Typography>
                        </Box>
                        <Box sx={sectionSecond.box2}>
                            <Button sx={sectionSecond.grid2Btn} onClick={()=>goToexe()} variant="outlined">Get Started</Button>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}
