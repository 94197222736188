import React, { useEffect, useState } from 'react'
import { Box,Container, Grid, Typography } from '@mui/material'
import { useHref, useNavigate} from 'react-router-dom'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@emotion/react';


export default function Header() {
    const navigate        = useNavigate()
    const parms           = useHref()
    const [name,setName]  = useState()
    const {term}       = useTheme()
    const {title,box,container} = term.header
//   const matches         = useMediaQuery('(max-width:767px)');

  const  nameHanlder = (parms_) =>{
    let name = ''
    switch (parms_) {
      case '/about-us':
        name = 'About Us'
        break;
      case '/privacy':
        name = 'Privacy Policy'
        break;
      case '/terms':
        name = 'Term & Conditions'
        break;
      case '/return-refund':
      name = 'Cancellation and Refund'
        break;
      case '/support':
        name = 'Support'
        break;
      case '/faqs':
        name = 'Faqs'
        break;  
      default:
        break;
    }
    setName(name)
  }

  useEffect(()=>{
    nameHanlder(parms)
  },[])

  useEffect(()=>{
    nameHanlder(parms)
  },[navigate])


  return (
    <Container maxWidth={"100%"} sx={container} component={"header"}>
      <Box p={5} bgcolor={"#171e30"} sx={box}>
          <Grid container className='container'style={{cursor:"pointer"}} onClick={()=>{navigate('/')}} textAlign={"center"} alignItems={"center"}>
            <Grid item xs={12} className='logoContainer' fontSize={{xs:"45px",md:"80px"}} fontWeight={700}>
              <Typography variant='span' component={'span'} fontFamily={'nunito, sans-serif'} color={"#00a884"}>WA</Typography>
              <Typography variant='span' component={'span'} fontFamily={'nunito, sans-serif'} color={"#fff"}>Blasters</Typography>
            </Grid>
            <Grid item sx={title} xs={12}>{name}</Grid>
          </Grid>
      </Box>
    </Container>
  )
}
