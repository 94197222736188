import React from 'react'
import { Container,Box, Grid, Typography} from '@mui/material';
import { useTheme } from '@mui/material/styles';

export default function Faqs() {
    const themes = useTheme();
    const {about} = themes

    return (
        <Container>
            <Box>
                <Grid container>
                    <Grid item xs={12} sx={about.grid2}>
                       <Typography sx={about.Typo1}>
                            WABlaster is a software owned and operated by 
                            <Typography sx={about.Typo2} component={"a"} href="#"> WABlasters</Typography>
                        </Typography>
                        <Typography sx={about.Typo3}>
                            It's a tech company dedicated to bringing you cutting-edge software products at competitive fair pricing. 
                        </Typography> 
                        <Typography sx={about.Typo3}>
                            At WABlaster, we enhance your communication experience with our advanced WhatsApp message sender. Perfect for businesses and personal use, our app simplifies messaging, making it efficient and effective. Whether you're running marketing campaigns, providing customer support, or staying in touch with loved ones, WABlaster ensures timely and reliable message delivery. Join us and elevate your communication to the next level.
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}
