import React, { useEffect, useState } from 'react'
import { useTheme } from '@mui/material/styles';
import { Container,Box, Grid, Typography, Card, Chip, Snackbar} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {getLicence} from '../utils/background'
import { useSearchParams,useNavigate } from 'react-router-dom';

export default function Success() {
  const themes = useTheme();
  const {sectionOne,success} = themes
  const [licence,setLicence] = useState([])
  const [searchParms,setSearchParms] = useSearchParams()
  const navigate = useNavigate()
  const sessionId = searchParms.get('session')
  const [error,setError] = useState('')
  const [openSnackbar,setSnackbar] = useState(false)

  useEffect(()=>{
    if (sessionId){
      (async()=>{
        let response = await getLicence(sessionId)
        let {status,license} = response
        if (status === 200){
          setLicence(license)
        } else {
          setError('Not found')
        }
      })() 
    } else {
      navigate('/')
    }
  },[])

  const handleClick = (licence) => {
    navigator.clipboard.writeText(licence).then(() => {
      setSnackbar(true)
    }).catch(err => {
      //
    });
    setTimeout(()=>{
      setSnackbar(false)
    },4000)
  }


  return (
    <>
    <Box component={"section"} sx={sectionOne.containerSX} className='wabSuccessSection'>
      <Container sx={success.container}>
        <Grid container sx={success.gridContainer}>
          <Grid item xs={12} md={8}>
          <Card sx={success.card}>
            <Grid container>
              <Grid item xs={12}>
                <Box sx={success.typoBox}>
                  <Typography sx={success.typo}>Your WABlasters Licences</Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box sx={success.licenceBox}>
                <Grid container>
                  {licence.length > 0 ?
                    licence.map((code)=>{
                      return(
                      <Grid item xs={6} md={3} mb={2}>
                        <Box>
                          <Chip
                            label={code}
                            onClick={()=>{handleClick(code)}}
                            onDelete={()=>{handleClick(code)}}
                            deleteIcon={<ContentCopyIcon />}
                          />  
                        </Box>
                      </Grid>
                     )
                    })
                  :
                  <Grid item xs={12}>
                    <Box>
                      <Typography>{error}</Typography>
                    </Box>
                  </Grid>
                }
                </Grid>
                </Box>
              </Grid>
            </Grid>
          </Card>
          </Grid>   
        </Grid>
      </Container>
    </Box>
    <Snackbar
      anchorOrigin={{ vertical:"bottom", horizontal:"right" }}
      open={openSnackbar}
      message="Copied!"
    />
    </>
    
  )
}
